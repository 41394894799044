html, body {
  background: black;
  width: 100vw;
  height: 100%; /* Using 100% because 100vh on mobiles will not include the navbar*/
}

#root {
  height:100%;
  width: 100%;
}

.App {
  text-align: center;
  position: relative;
  overflow: hidden;
  height: 100%;
  width: 100%;
}

.App-scroll {
  overflow: scroll;
  background: black;

}

canvas {
  width: 100%;
  height: 100%;
}

.navBar {
  text-align: left;
  color: white;

  &--black {
    header {
      background-color: black;
    }
  }
}

.MuiPaper-root {
  border-bottom: 1px #4d4d4d solid;
}

.white {
  color: white;
}

.buttons-container {
  position: fixed;
  z-index: 1000;
  bottom: 0px;
  right: 0;
  padding: 5px;
  display: flex;
  align-content: flex-start;
  justify-content: flex-start;
}

/*ipad mini*/
@media screen and (min-width: 768px) {
  .buttons-container {
    bottom: 0;
  }
}

.buttons-container--left {
  left:0;
  right: inherit;
}

.buttons-container--left-helper {
  //left:5vw;
  right: inherit;
}

.buttons-container--mid-right {
  display: flex;
  flex-direction: column;
  right:0;
  bottom: 45%;
  /*right: inherit;*/
}

.helperText {
  color: white;
  display: none;
  margin: 5px 5px;
  text-decoration: underline;
  cursor: pointer;

  @media screen and (min-width: 768px) {
    display: block;
  }
}

.pointer {
  cursor: pointer;
}

.slide-out-gallery {
  position:absolute;
  right: 0;
  /*width: 100px;*/
  height: 100vh;
  z-index:10;
  overflow:scroll;
  transform: translateX(100%);
  -webkit-transform: translateX(100%);
}

.slide-in {
  animation: slide-in 0.5s forwards;
  -webkit-animation: slide-in 0.5s forwards;
}

.slide-out {
  animation: slide-out 0.5s forwards;
  -webkit-animation: slide-out 0.5s forwards;
}

@keyframes slide-in {
  100% { transform: translateX(0%); }
}

@-webkit-keyframes slide-in {
  100% { -webkit-transform: translateX(0%); }
}

@keyframes slide-out {
  0% { transform: translateX(0%); }
  100% { transform: translateX(100%); }
}

@-webkit-keyframes slide-out {
  0% { -webkit-transform: translateX(0%); }
  100% { -webkit-transform: translateX(100%); }
}

.slide-out-gallery-container {
  margin-top: 65px; /* height of nav bar */
  flex-direction: column;
  display: flex;
  align-content: center;
  justify-content: center;
}

.slide-out-gallery-image {
  width: 300px;
  height: auto;
}

.closeModalButton {
  cursor: pointer;
  position: absolute !important;
  right: 5px !important;
  top:5px !important;
}

.home-screen-image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  width: 100%;
  height: 100%;
}

.home-screen-cover {
  display: flex;
  position: absolute;
  width:100%;
  height:100%;
  background: rgb(2,0,36);
  background: radial-gradient(circle, rgba(2,0,36,1) 0%, rgba(255,255,255,0) 0%, rgba(0,0,0,1) 100%);
  opacity: 0.9;
  justify-content: center;
  align-items: center;
}

.home-screen-button {
  color: black !important;
  border-color: white !important;
  background-color: white !important;
  font-weight: bold !important;
}

.drawer-link {
  color: black;
  text-decoration: none !important;
}

.main-title {
  cursor: pointer;
}

.main-title-navbar {
  padding: 8px;
  padding-left: 14px;
}

.info-map {
  min-height: 40vh;
}

.closeModalButton--large {
  cursor: pointer;
  position: absolute !important;
  right: 35px !important;
  bottom:30px !important;
}

.infoModal__button-container {
  display: flex;
  justify-content: flex-end;

  &-end {
    display: flex;
    justify-content: end !important;
  }
  &-between {
    display: flex;
    justify-content: space-between !important;
  }
}
.closeInfoModalButton--large {
  cursor: pointer;
  //position: absolute !important;
  //right: 35px !important;
  //bottom:30px !important;
}
.mintModalButton--large {
  cursor: pointer;
  align-self: flex-end;
  margin-right: 4px !important;
  //position: absolute !important;
  //left: 35px !important;
  //bottom:30px !important;
}

.connectButton {
  margin-left: 5px !important;
}

.disconnectButton {
  margin-left: 5px !important;
}

.red {
  color: red;
}

.connect-button-modal {
  float: right !important;
}

.infoModal__mobile-content {
  display: block;

  * {
    font-size: 12px !important;
  }

  @media screen and (min-width: 768px) {
    display: none;
  }
}
.infoModal__mobile-warning {
  color: red;
  //font-size: 2em;
}

.infoModal__desktop-content {
  display: none !important;
  @media screen and (min-width: 768px) {
    display: block !important;
  }
}

.characterTitle {
  border: 1px solid #707070;
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
  background-color: #e3e3e3;

  div {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  h1 {
    display: block;
    margin-left: 10px;
  }
  span {
    display: block;
    margin-left: 15px;
  }
}

// careful changing this becuase it is used in 2 places (info modal and as overlay)
.object-color-box {
  display: flex;
  flex-direction: row;

  div {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    width: 90px;
    height: 90px;
    cursor: pointer;

    &.selected {
      border: 5px solid cyan;
    }
  }
}

.rainbow {
  background-image: linear-gradient(to right, red,orange,yellow,green,blue,indigo,violet) !important;
}

.green {
  color: green;
}

.hidden {
  display: none !important;
}

.red-input {
  input {
    color: red;
  }
}
.green-input {
  input {
    color: green;
  }
}

.extra-box-shadow {
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}

.lowGraphicsButton {
  //position: absolute;
  //right: 0;
}




.movementControls {
  position: fixed;
  bottom: 0;
  //top: 50%;
  //left:50%;
  z-index: 999;
  width: 100%;
  height: 35vh;

  padding: 5px;
  font-size: 30px !important;
  //pointer-events: none;
  user-select: none;

  &__container {
    position: relative;
    height: 100%;
  }

  .rightPan {
    grid-area: 2 / 3 / 3 / 4;

    background-color: white;
    padding: 5px;
    font-size: 20px !important;
    //pointer-events: none;
    user-select: none;
  }
  .leftPan {
    grid-area: 2 / 1 / 3 / 2;

    background-color: white;
    padding: 5px;
    font-size: 20px !important;
    //pointer-events: none;
    user-select: none;
  }

  &__movementContainer {
    position: absolute;
    top: 0;
    left: 10%;

    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
  }
  &__panContainer {
    position: absolute;
    top: 0;
    right: 10%;

    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
  }
  &__buttonsContainer {
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 30%;
    left: calc(50% - 62px);
    //top: 0;
    //right: 10%;
    //
    //display: grid;
    //grid-template-columns: repeat(3, 1fr);
    //grid-template-rows: repeat(3, 1fr);
    //grid-column-gap: 0px;
    //grid-row-gap: 0px;
  }

  &__buttonsContainerKeys {
    display: flex;
    flex-direction: row;
  }

  .pPress {
    background-color: white;
    padding: 5px;
    font-size: 20px !important;
    margin-right: 5px;
  }

  .ePress {
    background-color: white;
    padding: 5px;
    font-size: 20px !important;
    margin-right: 5px;
  }
  .iPress {
    background-color: white;
    padding: 5px;
    font-size: 20px !important;
    margin-right: 5px;
  }

  .spacePress {
    background-color: white;
    padding: 5px;
    font-size: 20px !important;
    margin-top: 5px;
    margin-right: 5px;
  }

  .rightMove {

    grid-area: 2 / 3 / 3 / 4;

    background-color: white;
    padding: 5px;
    font-size: 20px !important;
    //pointer-events: none;
    user-select: none;

  }
  .upMove {
    grid-area: 1 / 2 / 2 / 3;

    background-color: white;
    padding: 5px;
    font-size: 20px !important;
    //pointer-events: none;
    user-select: none;

  }
  .leftMove {
    grid-area: 2 / 1 / 3 / 2;

    background-color: white;
    padding: 5px;
    font-size: 20px !important;
    //pointer-events: none;
    user-select: none;

  }
  .downMove {
    grid-area: 3 / 2 / 4 / 3;

    background-color: white;
    padding: 5px;
    font-size: 20px !important;
    //pointer-events: none;
    user-select: none;

  }
}

.bold-hint {
  font-size: 18px;
  color: green;
  font-weight: bold;
}


.home-page {

  &__container {
    display: grid;
    background-color: black;
    width:100%;
    height: 100%;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
    grid-template-areas:
    "One"
    "Two"
    "Three"
    "Four"
    "Five"
    "Six"
    "Zero";

    @media screen and (min-width: 768px) {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
      grid-template-areas:
    "Zero Zero"
    "One Two"
    "Four Four"
    "Three Three"
    "Five Five"
    "Six Six";
    }

    margin-top: 65px; // navbar height
    @media screen and (min-width: 768px) {
      margin-top: 0;
    }
  }

  &__Zero {
    grid-area: Zero;
    height: 65px; // navbar height
  }
  &__Four {
    grid-area: Four;

    margin: 20px 0;
  }
  &__Five {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    justify-items: center;
    align-items: center;
    background-color: darkgrey;
    color: white;
    padding: 15px;
    //font-size: 30px;
    //height: 20vh;
    grid-area: Five;

    margin: 20px 0;
  }
  &__Six {
    display: flex;
    align-content: center;
    justify-content: center;
    justify-items: center;
    align-items: center;
    //background-color: darkgrey;
    color: white;
    padding: 15px;
    font-size: 30px;
    height: 20vh;
    grid-area: Six;

    margin: 20px 0;
  }

  &__logos-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin: 0 auto;
    width: 100%;
    align-content: center;
    justify-content: center;
    justify-items: center;
    align-items: center;
    padding:5px;
    background-color: darkgray;

    @media screen and (min-width: 768px) {
      flex-direction: row;
    }


    img {
      width: 100px;
      height: 50px;
      margin-left: 30px;
    }

    span {
      margin-left: 30px;
      display: flex;
      align-self: center;
      justify-self: center;
      color: white;
    }
  }

  &__One {
    grid-area: One;
    position: relative;

    img {
      width: 100%;
      height: 100%;
    }
  }

  &__Two {
    grid-area: Two;
    position: relative;

    img {
      width: 100%;
      height: 100%;
    }
  }

  &__Three {
    grid-area: Three;
    //height: 50vh;
    padding: 20px 20px;
    //font-size: 24px;
    h1 {
      color: white;
    }
    span {
      color: white;
    }
  }

  &__image {

  }


  &__selectTextContainer {
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__selectText {
    margin: 0;
    //height: 100%;
    color: green;
    //font-size: 100px;
    font-size: 80px;
    font-weight: bold;
    opacity: 0.8;

    @media screen and (min-width: 768px) {
      font-size: 80px;
    }
  }

  &__selectable {
    border: 15px solid black;

    &:hover {
      border: 15px solid green;
      cursor:pointer;
    }
  }

}



.xr-button {
  position: absolute;
  bottom: 20px;
  padding: 12px 16px;
  border: 1px solid rgb(255, 255, 255);
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.1);
  //color: rgb(255, 255, 255);
  font: 13px sans-serif;
  text-align: center;
  opacity: 0.5;
  outline: none;
  z-index: 1000;
  cursor: auto;
  left: calc(50% - 50px);
  //width: 150px;


  @media screen and (min-width: 768px) {
    position: absolute;
    bottom: 20px;
    padding: 12px 16px;
    border: 1px solid rgb(255, 255, 255);
    border-radius: 4px;
    background: rgba(0, 0, 0, 0.1);
    //color: rgb(255, 255, 255);
    font: 13px sans-serif;
    text-align: center;
    opacity: 0.5;
    outline: none;
    z-index: 999;
    cursor: auto;
    left: calc(50% - 50px);
    //width: 150px;
  }
}

.centre-overlay {
  font-size: 300px;
  z-index:9999;
  color: white;
  position: fixed;
  top: 50%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
}
.time-overlay {
  font-size: 10px;
  z-index:9999;
  color: white;
  position: fixed;
  top: 10%;
  left: 10%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
}

.centre-point {
  font-size: 50px;
  z-index:10;
  color: white;
  position: fixed;
  top: 50%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
}

.slideModal {
  width: 90vw !important;
  @media screen and (min-width: 768px) {
    max-width: 500px;
  }
}

.unegma-logo {
  display: flex;
  flex-direction: column; // this is so the down arrow shows on the home screen
}



//html,
//body,
//#root {
//  margin: 0;
//  padding: 0;
//  overflow: hidden;
//  height: 100vh;
//  width: 100vw;
//  user-select: none !important;
//  background: gray;
//}

#mobileInterface {
  position: fixed;
  width: calc(100% - 20px);
  height: 50vh;
  pointer-events: none;
  z-index: 11;
  bottom: 30px;
  left: 30px;
  touch-action: manipulation;
}

#joystickWrapper1 {
  pointer-events: auto;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: transparent;
  width: 120px;
  height: 120px;
  z-index: 12;
  touch-action: manipulation;
  background-color: rgba(red, 0.2);
}

.noSelect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}
